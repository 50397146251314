import * as React from "react";
import { useHistory } from "react-router-dom";

import { CustomDataAdaptor, DataManager } from "@syncfusion/ej2-data";
import {
  Day,
  Inject,
  ResourceDirective,
  ResourcesDirective,
  ScheduleComponent,
  ViewDirective,
  ViewsDirective,
  Week,
} from "@syncfusion/ej2-react-schedule";

import EditorTemplate from "./EditorTemplate";

import "./Schedule.css";
import * as SupabaseAdaptor from "../../adapters/SupabaseAdaptor";

const defaultResources = [
  { text: "WCA", id: 10, color: "#B391B5" }, // purple
];

const dataManager = new DataManager({
  adaptor: new CustomDataAdaptor({
    getData: SupabaseAdaptor.getData,
    addRecord: SupabaseAdaptor.addRecord,
    updateRecord: SupabaseAdaptor.updateRecord,
    deleteRecord: SupabaseAdaptor.deleteRecord,
    batchUpdate: SupabaseAdaptor.batchUpdate,
  }),
});

const fieldsData = {
  id: { name: "Id" },
  subject: { name: "Student" },
  location: { name: "Tutor" },
};

function WCA() {
  let scheduler = null;
  let recurrObj = null;

  const history = useHistory();

  const destroyed = (args) => {
    if (window.location.href.includes("/schedule")) {
      window.location.reload();
    }
  };

  const navigating = (args) => {
    if (args.action === "view") {
      if (args.currentView === "Week") {
        scheduler.enableAdaptiveUI = true;
      } else {
        scheduler.enableAdaptiveUI = false;
      }
    }
  };

  const onActionBegin = (args) => {
    if (args.requestType === "toolbarItemRendering") {
      const loft = {
        align: "Right",
        text: "Loft",
        cssClass: "schedule-loft",
      };
      args.items.push(loft);

      const marketplace = {
        align: "Right",
        text: "MarketPlace",
        cssClass: "schedule-marketplace",
      };
      args.items.push(marketplace);
    }
  };

  const onActionComplete = (args) => {
    const scheduleElement = document.getElementById("scheduler");
    if (args.requestType === "toolBarItemRendered" && scheduleElement) {
      const loft = scheduleElement.querySelector(".schedule-loft");
      if (loft) {
        loft.onclick = () => {
          history.push("/schedule");
        };
      }

      const marketplace = scheduleElement.querySelector(
        ".schedule-marketplace"
      );
      if (marketplace) {
        marketplace.onclick = () => {
          history.push("/schedule-marketplace");
        };
      }
    }
  };

  const onEventRendered = (event) => {
    if (event.data.Cancelled) {
      event.element.style.backgroundColor = "#AEAEAE";
    }
  };

  const popupOpen = (args) => {
    console.log(args.type);
    switch (args.type) {
      case "RecurrenceAlert":
        console.log(args);
        if (args.data.RecurrenceException) {
          args.cancel = true;
          scheduler.openEditor(args.data, "EditOccurrence");
        }
        break;

      case "Editor":
        args.duration = 60;
        break;

      default:
        break;
    }
  };

  const popupClose = (args) => {
    if (args.type === "Editor") {
      if (args.data) {
        if (recurrObj.value) {
          args.data.RecurrenceRule = recurrObj.value;
        }
      }
    }
  };

  const setReccurrence = (args) => {
    recurrObj = args;
  };

  const editorTemplate = (eventProps) => {
    if (Object.keys(eventProps).length === 0) return;

    return (
      <EditorTemplate
        eventProps={eventProps}
        resources={defaultResources}
        setReccurrence={setReccurrence}
      />
    );
  };

  return (
    <ScheduleComponent
      eventSettings={{
        dataSource: dataManager,
        fields: fieldsData,
        editFollowingEvents: true,
      }}
      width="100%"
      height="100vh"
      id="scheduler"
      cssClass="scheduler"
      currentView="Day"
      startHour="06:00"
      endHour="20:00"
      workHours={{ highlight: false, start: "06:00", end: "20:00" }}
      group={{ resources: ["Rooms"] }}
      enableAdaptiveUI={false}
      navigating={navigating}
      destroyed={destroyed}
      popupOpen={popupOpen}
      popupClose={popupClose}
      ref={(obj) => {
        scheduler = obj;
      }}
      editorTemplate={editorTemplate}
      eventRendered={onEventRendered}
      actionBegin={onActionBegin}
      actionComplete={onActionComplete}
    >
      <ResourcesDirective>
        <ResourceDirective
          field="Room"
          title="Room Type"
          name="Rooms"
          allowMultiple={true}
          dataSource={defaultResources}
          textField="text"
          idField="text"
          colorField="color"
        />
      </ResourcesDirective>
      <ViewsDirective>
        <ViewDirective
          option="Day"
          group={{ enableCompactView: false, byDate: true }}
        />
        <ViewDirective
          option="Week"
          group={{ enableCompactView: true, byDate: false }}
        />
      </ViewsDirective>
      <Inject services={[Day, Week]} />
    </ScheduleComponent>
  );
}

export default WCA;
