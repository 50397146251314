import { supabase } from "../supabase";

const supaBaseUrl = process.env.REACT_APP_SUPABASE_URL;
const eventTable = process.env.REACT_APP_SUPABASE_EVENTS_TABLE;
const eventTableUrl = `${supaBaseUrl}/rest/v1/${eventTable}`;

const httpRequest = (xhttp, option, data = {}) => {
  xhttp.onreadystatechange = () => {
    if (xhttp.readyState === 4) {
      option.httpRequest = xhttp;
      if (
        (xhttp.status >= 200 && xhttp.status <= 299) ||
        xhttp.status === 304
      ) {
        let data = "";
        if (xhttp.responseText) {
          data = JSON.parse(xhttp.responseText);
        }
        option.onSuccess(data, option);
      } else {
        option.onFailure(option);
      }
    }
  };

  xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
  xhttp.setRequestHeader("apikey", process.env.REACT_APP_SUPABASE_ANON_KEY);
  xhttp.setRequestHeader(
    "Authorization",
    `Bearer ${supabase.auth.currentSession.access_token}`
  );
  xhttp.send(data);
};

export const getData = (option) => {
  let url = eventTableUrl;
  // (RecurrenceID == null && StartTime >= [Current scheduling period]) &&    <Non-Recurring events in the current scheduling period>
  // (RecurrenceID != null && StartTime >= [Current scheduling period]) && (GUID == null || RecurrenceException > [CurrentYear]) <Recurring events in the current scheduling period - 6 months of Exceptions (Cancelled/Modified events)>
  url = `${eventTableUrl}?or=(and(RecurrenceID.is.null,StartTime.gte.2024-08-01+00:00:00+EST),and(RecurrenceRule.like.*FREQ*,StartTime.gte.2024-08-01+00:00:00+EST)))`;

  let xhttp = new XMLHttpRequest();
  xhttp.open("GET", url);
  httpRequest(xhttp, option);
};

export const addRecord = (option) => {
  let url = eventTableUrl;
  let data = JSON.parse(option.data);

  // Remove the ID from the new records. The correct ID will be generated by the DB
  data.added.forEach((record) => {
    delete record.Id;
    delete record["[object Object]"];
  });

  let xhttp = new XMLHttpRequest();
  xhttp.open("POST", url);
  xhttp.setRequestHeader("Prefer", "return=representation");
  xhttp.setRequestHeader("Prefer", "resolution=merge-duplicates");
  httpRequest(xhttp, option, JSON.stringify(data.added));
};

export const updateRecord = (option) => {
  let url = eventTableUrl;
  let data = JSON.parse(option.data);

  // Remove the GUID from the changed records
  data.changed.forEach((record) => {
    delete record["[object Object]"];
  });

  let xhttp = new XMLHttpRequest();
  xhttp.open("POST", url);
  xhttp.setRequestHeader("Prefer", "return=representation");
  xhttp.setRequestHeader("Prefer", "resolution=merge-duplicates");
  httpRequest(xhttp, option, JSON.stringify(data.changed));
};

export const deleteRecord = (option) => {
  let url = eventTableUrl;
  let data = JSON.parse(option.data);
  let query = `${url}?Id=eq.${data.deleted[0].Id}`;

  let xhttp = new XMLHttpRequest();
  xhttp.open("DELETE", query);
  httpRequest(xhttp, option);
};

export const batchUpdate = (option) => {
  let data = JSON.parse(option.data);

  if (data.added.length > 0) {
    addRecord(option);
  }

  if (data.changed.length > 0) {
    updateRecord(option);
  }

  if (data.deleted.length > 0) {
    deleteRecord(option);
  }

  console.log(data);
};
