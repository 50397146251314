import * as React from 'react';

import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons'
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars'
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns'
import { RecurrenceEditorComponent } from '@syncfusion/ej2-react-schedule'

import { 
  Grid, 
  Stack } from '@mui/material'

const ONE_HOUR = 1 * 60 * 60 * 1000

function EditorTemplate(props) {
  console.log(props)
  props.setReccurrence({})

  let eventProps = props.eventProps

  const [student, setStudent] = React.useState(eventProps.Student)
  const [tutor, setTutor] = React.useState(eventProps.Tutor)
  const [startTime, setStartTime] = React.useState(eventProps.StartTime)
  const [endTime, setEndTime] = React.useState(new Date(eventProps.StartTime.getTime() + ONE_HOUR))
  const [room, setRoom] = React.useState(eventProps.Room)

  const onStartTimeChanged = (value) => {
    setEndTime(new Date(value.getTime() + ONE_HOUR))
    setStartTime(value)
  }

  return (
    <Grid container 
      spacing={2} 
      style={{paddingTop:'12px'}}
      alignItems="flex-end"
    >
      <Grid item xs={6}>
        <label>Student</label>
        <input 
          id="Student" 
          className="e-field e-input"
          name="Student"
          value={student}
          onChange={ (target) => setStudent(target.value) }
        />
      </Grid>
      <Grid item xs={6}>
        <label>Tutor</label>
        <input 
          id="Tutor" 
          className="e-field e-input"
          name="Tutor"
          value={tutor}
          onChange={ (target) => setTutor(target.value) }
        />
      </Grid>
      <Grid item xs={6}>
        <Stack>
          <label>Start</label>
          <DateTimePickerComponent
            format='MM/dd/yy hh:mm a'
            id="StartTime"
            data-name="StartTime"
            value={startTime} 
            className="e-field"
            onChange={ (target) => onStartTimeChanged(target.value) }
          />
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack>
          <label>End</label>
          <DateTimePickerComponent
            format='MM/dd/yy hh:mm a'
            id="EndTime"
            data-name="EndTime"
            value={ endTime } 
            className="e-field"
            onChange={ (target) => setEndTime(target.value) }
          />
        </Stack>
      </Grid>   
      <Grid item xs={12}>
        <RecurrenceEditorComponent 
          ref={t => props.setReccurrence(t)} 
          id='RecurrenceEditor'
          value={eventProps.RecurrenceRule}
        />
      </Grid>
      <Grid item xs={6}>
        <label>Room</label>
        <DropDownListComponent 
          id='Room' 
          dataSource={props.resources}
          placeholder="Room"
          className='e-field'
          value={room}
          onChange={ (target) => setRoom(target.value) }
        />
      </Grid>
      <Grid item xs={6} alignItems="center">
        <CheckBoxComponent
          label='Cancelled'
          id='Cancelled'
          name='Cancelled'
          className='e-field'
          checked={eventProps.Cancelled}
        />
      </Grid>
    </Grid>
  )
}

export default EditorTemplate